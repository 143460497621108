/*
* Updated w/ insertAdjacentElement
* @author Dan Levy @justsml
* 2016-06-23
*
* Credit: @lyleunderwood - afterend patch/fix
*
* ```js
* import { insertAdjacentElement } from './libs/insertAdjacentHTML.js';
* Use either:
* HTMLElement.prototype.insertAdjacentElement = HTMLElement.prototype.insertAdjacentElement || insertAdjacentElement;
* // Or, polyfill based on the current correct DOM3 API:
* Node.prototype.insertAdjacentElement        = Node.prototype.insertAdjacentElement || insertAdjacentElement;
* ```
*
* ========
* insertAdjacentHTML.js
*   Cross-browser full HTMLElement.insertAdjacentHTML implementation.
*
* 2011-10-10
*
* By Eli Grey, http://eligrey.com
* Public Domain.
* NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
*/
function insertAdjacentElement(position, elem) {
  const _this = this;
  const parent = _this.parentNode;
  let node, first;

  switch (position.toLowerCase()) {
    case 'beforebegin':
      while ((node = elem.firstChild)) {
        parent.insertBefore(node, _this);
      }
      break;
    case 'afterbegin':
      first = _this.firstChild;
      while ((node = elem.lastChild)) {
        first = _this.insertBefore(node, first);
      }
      break;
    case 'beforeend':
      while ((node = elem.firstChild)) {
        _this.appendChild(node);
      }
      break;
    case 'afterend':
      parent.insertBefore(elem, _this.nextSibling);
      break;
    default:
      throw new Error(`${position} not one of beforeBegin, afterBegin, beforeEnd, or afterEnd`);
  }
  return elem;
}

Node.prototype.insertAdjacentElement =
  Node.prototype.insertAdjacentElement || insertAdjacentElement;
